import React from 'react'
import { getTagElement } from '../../utils/utils'
import './NewsListItem.css'

const NewsListItem = ({
  item,
  isExternal,
  renderFields,
  styleItem,
  onMouseEnter,
  onMouseLeave,
  onClick
}) => {
  const link = getTagElement(item.excerpt.rendered, 'p')

  return (
    <div
      className="news-list-item-wrapper"
      style={styleItem}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      {isExternal ? (
        <a
          className="news-list-item-title"
          target="_blank"
          href={link}
          rel="noreferrer">{`${item.title.rendered} ↗`}</a>
      ) : (
        <div className="news-list-item-title" onClick={onClick}>{`${item.title.rendered}`}</div>
      )}
      <div className="news-list-item-fields">{renderFields(item)}</div>
    </div>
  )
}

export default NewsListItem
