import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { routes } from './constants/routes'
import { FOOTER_POST } from './constants/wordpress'
import { getPost } from './services/posts'
import './App.css'
import { AppContext } from './context/App'

const App = () => {
  const [footerInfo, setFooterInfo] = useState(null)

  useEffect(() => {
    getPost(FOOTER_POST, setFooterInfo)
  }, [])

  return (
    <div className="App">
      <AppContext.Provider value={{ footerInfo }}>
        <Router>
          <Routes>
            {routes.map(
              ({ path, element }, index) =>
                element && <Route exact path={path} element={element} key={`route-${index}`} />
            )}
          </Routes>
        </Router>
      </AppContext.Provider>
    </div>
  )
}

export default App
