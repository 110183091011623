import React, { useState, useEffect } from 'react'
import './Project.css'
import { useParams } from 'react-router'
import Navbar from '../../components/navbar/Navbar'
import { getPost } from '../../services/posts'
import Slideshow from '../../components/slideshow/Slideshow'
import ProjectContent from '../../components/projectContent/ProjectContent'
import LoadingFull from '../../components/loading/LoadingFull'
import ImageLoader from '../../components/image-loader/ImageLoader'

const parseProject = (project) => {
  const postContent = project.content.rendered
  const title = project.title.rendered

  const div = document.createElement('div')
  div.innerHTML = postContent

  const contentImages = div.getElementsByClassName('slideshow')

  if (!contentImages || !contentImages[0]) return false
  const elements = contentImages && contentImages[0].children

  const items = [].slice.call(elements)

  const images = items
    .map((item) => {
      const src = item.children[0].src
      const imageSrcSplit = src.split('?')
      const imageSrc = imageSrcSplit.length > 1 ? imageSrcSplit[0] : src
      return { image: imageSrc, caption: item.children[1].innerHTML }
    })
    .filter(({ image }) => image !== '')

  const contentDescription = div.getElementsByClassName('description')
  const description = contentDescription[0].innerHTML

  const contentInformation = div.getElementsByClassName('infos')
  const information = contentInformation[0].innerHTML

  return { title, images, description, information }
}

const Project = () => {
  const { id } = useParams()

  const [project, setProject] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    getPost(id, (project) => {
      setProject(project)
      setLoading(false)
    })
  }, [id])

  const {
    title = '',
    images = [],
    description = '',
    information = ''
  } = (project && parseProject(project)) || {}

  const imagesPreload = images?.length > 0 && images.map(({ image }) => image)

  return (
    <div className="page">
      <div className="page-content">
        <Navbar />
        {loading && <LoadingFull />}
        {images?.length > 0 && <ImageLoader imagesToLoad={imagesPreload} />}
        {images && images.length > 0 && <Slideshow imageArray={images} />}
        {/* {title && description && (
          <ProjectContent
            firstSection={<div dangerouslySetInnerHTML={{ __html: title }} />}
            secondSection={<div dangerouslySetInnerHTML={{ __html: information }} />}
            thirdSection={<div dangerouslySetInnerHTML={{ __html: description }} />}
            height={350}
          />
        )} */}
      </div>
      {title && description && (
        <ProjectContent
          firstSection={<div dangerouslySetInnerHTML={{ __html: title }} />}
          secondSection={<div dangerouslySetInnerHTML={{ __html: information }} />}
          thirdSection={<div dangerouslySetInnerHTML={{ __html: description }} />}
        />
      )}
      {/* <Footer /> */}
    </div>
  )
}

export default Project
