import Information from '../pages/information/Information'
import Home from '../pages/home/Home'
import NewsList from '../pages/news-list/NewsList'
import Project from '../pages/project/Project'
import Projects from '../pages/projects/Projects'
import Opere from '../pages/opere/Opere'

const routes = [
  {
    title: 'home',
    path: '/',
    navBarPages: false,
    element: <Home />
  },
  {
    title: 'Projects',
    path: '/projects',
    navBarPages: true,
    element: <Projects />
  },
  {
    title: 'News',
    path: '/news',
    navBarPages: true,
    element: <NewsList />
  },
  {
    title: 'Information',
    path: '/information',
    navBarPages: true,
    element: <Information />
  },
  {
    title: 'project',
    path: '/projects/:id',
    navBarPages: false,
    element: <Project />
  },
  {
    title: 'OPERE',
    path: '/opere',
    navBarPages: true,
    element: <Opere />
  }
]

const homeRoute = routes[0]

export { homeRoute, routes }
