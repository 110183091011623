import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { routes } from '../../constants/routes'
import './Navbar.css'

const Navbar = ({ opereMode }) => {
  const { pathname } = useLocation()

  const [openMenu, setOpenMenu] = useState(false)

  const navBarPages = routes.filter((r) => !!r.navBarPages)

  return (
    <div className="navbar-wrapper">
      <Link to="/" className="navbar-logo">
        <div className={`navbar-logo-image ${opereMode ? 'opereMode' : ''}`} />
      </Link>
      <div className="navbar-pages">
        {navBarPages.map(({ title, path }, index) => {
          const isSelected = pathname.includes(path)
          return (
            <Link
              className={`navbar-page ${isSelected ? 'selected' : ''} ${
                opereMode ? 'opereMode' : ''
              }`}
              to={path}
              key={index}>
              {title}
            </Link>
          )
        })}
      </div>
      <div
        className={`navbar-pages-mobile ${opereMode ? 'opereMode' : ''}`}
        onClick={() => setOpenMenu(!openMenu)}>
        <div className="navbar-hamburguer-icon" />
      </div>
      {openMenu && (
        <div className={`navbar-pages-mobile-menu ${opereMode ? 'opereMode' : ''}`}>
          {navBarPages.map(({ title, path }, index) => {
            const isSelected = pathname.includes(path)
            return (
              <Link
                className={`navbar-page-mobile ${isSelected ? 'selected' : ''} ${
                  opereMode ? 'opereMode' : ''
                }`}
                to={path}
                key={index}>
                {title}
              </Link>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default Navbar
