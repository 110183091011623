/* import { homeRoute, routes } from '../constants/routes'
import { PUBLICACAO_CATEGORY_ID, LIVROS_CATEGORY_ID } from '../constants/constants.api'
import niveisAcessibilidade from '../constants/niveisAcessibilidade'
import { MOBILE_WIDTH, TABLET_WIDTH } from '../constants/constants.ui' */

const getDateFromTimestamp = (timestamp) => new Date(timestamp)

const getWrittenMonth = (month) => {
  switch (month) {
    case 0:
      return 'Janeiro'
    case 1:
      return 'Fevereiro'
    case 2:
      return 'Março'
    case 3:
      return 'Abril'
    case 4:
      return 'Maio'
    case 5:
      return 'Junho'
    case 6:
      return 'Julho'
    case 7:
      return 'Agosto'
    case 8:
      return 'Setembro'
    case 9:
      return 'Outubro'
    case 10:
      return 'Novembro'
    case 11:
      return 'Dezembro'
    default:
      return ''
  }
}

export const getFullDate = (timestamp) => {
  const date = getDateFromTimestamp(timestamp)
  const day = date.getDate()
  const month = getWrittenMonth(date.getMonth())
  const year = date.getFullYear()

  return `${day} ${month} ${year}`
}

export const getDay = (timestamp) => {
  const date = getDateFromTimestamp(timestamp)
  return `${date.getDate()}`
}

export const getMonthYear = (timestamp) => {
  const date = getDateFromTimestamp(timestamp)
  const month = getWrittenMonth(date.getMonth())
  const year = date.getFullYear()

  return `${month} ${year}`
}

/* export const isNavBarItem = (path) => {
  const route = routes.find((ele) => ele.path === path)
  if (route && route.path === homeRoute.path) return true
  return route && route.navBarPages
} */

export const getTagElement = (htmlString, tag) => {
  const element = htmlString && htmlString.match(`<${tag}>(.*?)</${tag}>`)
  return element ? element[1] : htmlString
}

export const getTagElementDeep = (htmlString, tag) => {
  const div = document.createElement('div')
  // assing your HTML to div's innerHTML
  div.innerHTML = htmlString
  // get all <tag> elements from div
  const elements = div.getElementsByTagName(tag)

  return elements && elements.length > 0 ? elements[0] : htmlString
}

export const parseFaqs = (postContent) => {
  const postHtml = getTagElementDeep(postContent, 'perguntas')
  const div = document.createElement('div')
  div.innerHTML = postHtml.outerHTML
  const perguntas = div.getElementsByTagName('item')

  const items = [].slice.call(perguntas)
  const faqsObjectArray = []

  items.forEach((element) => {
    const question = getTagElementDeep(element.outerHTML, 'pergunta').innerHTML || ''
    const answer = getTagElementDeep(element.outerHTML, 'resposta').innerHTML || ''
    faqsObjectArray.push({ question, answer })
  })

  return faqsObjectArray
}

export const parseMapPoints = (postContent) => {
  const postHtml = getTagElementDeep(postContent, 'map')
  const div = document.createElement('div')
  div.innerHTML = postHtml.outerHTML
  const points = div.getElementsByTagName('point')

  const items = [].slice.call(points)
  const mapPointsArray = []

  items.forEach((element) => {
    const latValue = getTagElementDeep(element.outerHTML, 'lat').innerHTML || null
    const lngValue = getTagElementDeep(element.outerHTML, 'lon').innerHTML || null
    const nivel = getTagElementDeep(element.outerHTML, 'level').innerHTML || null
    const titulo = getTagElementDeep(element.outerHTML, 'title').innerHTML || null

    mapPointsArray.push({
      id: 'item',
      lat: latValue && Number(latValue),
      lng: lngValue && Number(lngValue),
      nivel: nivel && Number(nivel),
      titulo: titulo && String(titulo)
    })
  })

  return mapPointsArray
}

export const parseGallery = (postContent) => {
  const postHtml = getTagElementDeep(postContent, 'ul')
  const div = document.createElement('div')
  div.innerHTML = postHtml.outerHTML
  const imagens = div.getElementsByTagName('li')
  const items = [].slice.call(imagens)
  const imageArray = []

  items.forEach((element) => {
    const image = getTagElementDeep(element.outerHTML, 'img').src || null
    if (image) imageArray.push(image)
  })
  return imageArray
}

export const parseSlideshow = (postContent) => {
  const postHtml = getTagElementDeep(postContent, 'ul')
  const div = document.createElement('div')
  div.innerHTML = postHtml.outerHTML
  const imagens = div.getElementsByTagName('li')
  const items = [].slice.call(imagens)
  let imageObject

  items.forEach((element) => {
    const sourceUrl = getTagElementDeep(element.outerHTML, 'img').src || null
    const textString = getTagElementDeep(element.outerHTML, 'figcaption').innerHTML

    let textParsed = textString.replaceAll('”', '"')
    textParsed = textParsed.replaceAll('“', '"')

    const { titulo, subtitulo } = JSON.parse(textParsed)
    const link = getTagElementDeep(element.outerHTML, 'img').alt || null

    imageObject = { sourceUrl, title: titulo, description: subtitulo, link }
  })
  return imageObject
}

export const parseNumbers = (postContent) => {
  const postHtml = getTagElementDeep(postContent, 'div')
  const numbersBlock = postHtml.getElementsByClassName('wp-block-columns numbers-icvm')
  const items = [].slice.call(numbersBlock[0].children)
  const numbersArray = []

  items.forEach((element) => {
    const image = getTagElementDeep(element.outerHTML, 'img').src || null
    const number = getTagElementDeep(element.outerHTML, 'strong').innerHTML || null
    const text = getTagElementDeep(element.outerHTML, 'p').innerHTML || null
    numbersArray.push({ image, number, text })
  })

  return numbersArray
}

export const parseAtividades = (postContent) => {
  const postHtml = getTagElementDeep(postContent, 'atividades')
  const div = document.createElement('div')
  div.innerHTML = postHtml.outerHTML
  const atividades = div.getElementsByTagName('atividade')

  const items = [].slice.call(atividades)
  const atividadesObjectArray = []

  items.forEach((element) => {
    const id = getTagElementDeep(element.outerHTML, 'id').innerHTML || ''
    const title = getTagElementDeep(element.outerHTML, 'title').innerHTML || ''
    const description = getTagElementDeep(element.outerHTML, 'description').innerHTML || ''
    const external = getTagElementDeep(element.outerHTML, 'external').innerHTML
    atividadesObjectArray.push({ id, title, description, external })
  })

  return atividadesObjectArray
}

export const trimString = (string, max, addDots) => {
  const newString = string.substring(0, max)
  const greaterThanMax = string.length > newString.length
  return greaterThanMax && addDots ? `${newString}...` : newString
}

export const buildNewLinesFromString = (string) => string.replace(/(\r\n|\n|\r)/gm, '<br>')

export const getPostType = (pathname) => {
  if (pathname.includes('noticia')) return 'Notícia'
  if (pathname.includes('conferencia')) return 'Conferência'
  return ''
}

export const separateNivelText = (nivelText) => {
  const split = nivelText.split(' - ', 2)
  return { nivelNumber: split[0] || 'Nivel', nivelName: split[1] || 'Nome' }
}

/* export const isMobile = () => window.innerWidth <= MOBILE_WIDTH

export const isTablet = () => window.innerWidth <= TABLET_WIDTH */

export const mapProjectArray = (orderedList, posts, projects) => {
  const orderedProjects = parseAtividades(orderedList)

  const result = orderedProjects.map(({ id, external, title, description }) => {
    const post =
      posts.find(({ id: postId }) => id === String(postId)) ||
      projects.find(({ id: postId }) => id === String(postId))

    if (!!external && !!post) {
      post['external_link'] = external
    }
    if (!!title && !!post && !!post.projectList) {
      post['title'] = title
    }
    if (!!description && !!post && !!post.projectList) {
      post['description'] = description
    }

    return post
  })

  return result
}

export const getFormattedDate = (date) => {
  const dateObject = new Date(date)

  let day = dateObject.getDate()
  let month = dateObject.getMonth() + 1
  const year = dateObject.getFullYear()

  if (day < 10) day = `0${day}`
  if (month < 10) month = `0${month}`

  return `${day}-${month}-${year}`
}

export const getSeason = (date) => {
  const dateObject = new Date(date)

  const year = dateObject.getFullYear()
  const prevYear = year - 1

  return `${prevYear}-${year}`
}

export const randomIntFromInterval = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
