import React, { useState, useEffect, useRef } from 'react'
import './NewsList.css'
import { fetchPostsDatePreview, getPost } from '../../services/posts'
import Navbar from '../../components/navbar/Navbar'
import { NEWS_CATEGORY, NEWS_EXTERNAL_CATEGORY } from '../../constants/wordpress'
import FilterItem from '../../components/filterItem/FilterItem'
import Footer from '../../components/footer/Footer'
import { getFormattedDate } from '../../utils/utils'
import NewsContent from '../../components/newsContent/NewsContent'
import NewsListItem from '../../components/newsListItem/NewsListItem'
import LoadingFull from '../../components/loading/LoadingFull'
import Loading from '../../components/loading/Loading'
import { debounce } from 'lodash'
import { useLayoutEffect } from 'react'

const NewsList = () => {
  const [year, setYear] = useState('')
  const [newsList, setNewsList] = useState([])
  const [openNewsId, setOpenNewsId] = useState(null)
  const [newsContent, setNewsContent] = useState([])

  const [loading, setLoading] = useState(true)
  const [newsItemLoading, setNewsItemLoading] = useState(false)

  const [hasScroll, setHasScroll] = useState(false)
  const [innerHeight, setInnerHeight] = useState(window.innerHeight)

  useEffect(() => {
    setLoading(true)
    const yearQuery =
      year !== '' ? `&after=${year}-01-01T00:00:00&before=${year}-12-31T23:59:59` : ''
    fetchPostsDatePreview(
      `${NEWS_CATEGORY},${NEWS_EXTERNAL_CATEGORY}`,
      1,
      60,
      yearQuery,
      (news) => {
        setNewsList(news)
        setLoading(false)
      }
    )
  }, [year])

  useEffect(() => {
    if (openNewsId) {
      setNewsItemLoading(true)
      getPost(openNewsId, (newsContent) => {
        setNewsContent(newsContent)
        setNewsItemLoading(false)
      })
    }
  }, [openNewsId])

  const ref = useRef(null)

  useEffect(() => {
    if (!ref || !ref.current) return
    setHasScroll(ref.current.clientHeight + 144 > innerHeight)
  }, [ref.current, innerHeight])

  const debouncedHandleResize = debounce(() => {
    setInnerHeight(window.innerHeight)
  }, 100)

  useLayoutEffect(() => {
    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  return (
    <div className="page">
      <div className="page-scrollable-content">
        <Navbar>
          {/* <div className="filters-navbar-wrapper">
            <FilterItem label="All" onClick={() => setYear('')} isSelected={year === ''} />
            <FilterItem label="2022" onClick={() => setYear('2022')} isSelected={year === '2022'} />
            <FilterItem label="2021" onClick={() => setYear('2021')} isSelected={year === '2021'} />
            <FilterItem label="2020" onClick={() => setYear('2020')} isSelected={year === '2020'} />
          </div> */}
        </Navbar>
        {loading && <LoadingFull />}
        {!loading && (
          <div className="filters-navbar-wrapper">
            <FilterItem label="All" onClick={() => setYear('')} isSelected={year === ''} />
            <FilterItem label="2024" onClick={() => setYear('2024')} isSelected={year === '2024'} />
            <FilterItem label="2023" onClick={() => setYear('2023')} isSelected={year === '2023'} />
            <FilterItem label="2022" onClick={() => setYear('2022')} isSelected={year === '2022'} />
            <FilterItem label="2021" onClick={() => setYear('2021')} isSelected={year === '2021'} />
          </div>
        )}
        {newsList && newsList.length > 0 && (
          <div className="news-list-wrapper" ref={ref}>
            {newsList.map((newsItem) => {
              const { categories } = newsItem
              const isExternal = categories.includes(NEWS_EXTERNAL_CATEGORY)
              const isOpen = newsItem.id === openNewsId
              return (
                <>
                  <NewsListItem
                    key={newsItem.id}
                    item={newsItem}
                    isExternal={isExternal}
                    renderFields={({ date }) => <div>{getFormattedDate(date)}</div>}
                    onClick={() => {
                      if (isOpen) {
                        setOpenNewsId(null)
                        setNewsContent([])
                      } else {
                        setOpenNewsId(newsItem.id)
                      }
                    }}
                  />
                  {newsItemLoading && isOpen && <Loading label="Loading..." />}
                  {newsContent && newsContent.content && newsContent.content.rendered && isOpen && (
                    <NewsContent newsContent={newsContent} />
                  )}
                </>
              )
            })}
          </div>
        )}
        {hasScroll && <Footer containerStyle={{ marginTop: 0 }} />}
      </div>
      {!hasScroll && <Footer />}
    </div>
  )
}

export default NewsList
