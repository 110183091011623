import React from 'react'
import { getTagElement } from '../../utils/utils'
import './ListItem.css'

const getImageHeight = (url) => {
  var img = new Image()
  img.src = url

  const { height, width } = img

  const windowWidth = window.innerWidth - 30
  /* const windowHeight = window.innerHeight */

  /* const height = 1500 * (width / height) */

  /* const imageHeight = (height * windowWidth) / width */

  /* const ratio = Math.min(windowWidth / width, windowHeight / height) */

  const ratio = windowWidth / width

  return { width: width * ratio, height: width / (height / windowWidth) }
  /* return { height: imageHeight, width: windowWidth } */
}

const ListItem = ({
  item,
  isExternal,
  renderFields,
  styleItem,
  onMouseEnter,
  onMouseLeave,
  onClick
}) => {
  const link = getTagElement(item.excerpt.rendered, 'p')

  const { height } = getImageHeight(item.jetpack_featured_media_url)

  return (
    <div
      className="list-item-wrapper"
      style={styleItem}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}>
      {isExternal ? (
        <a
          className="list-item-title"
          target="_blank"
          href={link}
          rel="noreferrer">{`${item.title.rendered} ↗`}</a>
      ) : (
        <div>
          <div
            className="list-item-mobile-image"
            style={{ backgroundImage: `url(${item.jetpack_featured_media_url})`, height }}
          />
          <div className="list-item-title" to={`${item.id}`}>{`${item.title.rendered}`}</div>
        </div>
      )}
      <div className="list-item-fields">{renderFields(item)}</div>
    </div>
  )
}

export default ListItem
