import React, { useRef, useEffect } from 'react'
import Fade from 'react-reveal/Fade'
import './Slideshow.css'
import { Fade as SlideshowFade } from 'react-slideshow-image'
import SlideshowItem from './SlideshowItem'
import 'react-slideshow-image/dist/styles.css'

const properties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  autoplay: false,
  prevArrow: <div className="slideshow-arrow">←</div>,
  nextArrow: <div className="slideshow-arrow">→</div>
}

const Slideshow = ({ imageArray }) => {
  const slideRef = useRef()

  const pressHandler = ({ key }) => {
    if (key === 'ArrowLeft') {
      slideRef.current.goBack()
    }
    if (key === 'ArrowRight') {
      slideRef.current.goNext()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', pressHandler)

    return () => {
      window.removeEventListener('keydown', pressHandler)
    }
  }, [])

  return (
    <Fade>
      <div className="slideshow-wrapper">
        <SlideshowFade ref={slideRef} {...properties}>
          {imageArray.map(({ image, caption }, index) => (
            <SlideshowItem
              key={`slide-${index}}`}
              imageUrl={image}
              caption={caption}
              index={index}
              numberImages={imageArray.length}
            />
          ))}
        </SlideshowFade>
      </div>
    </Fade>
  )
}

export default Slideshow
