import React from 'react'
import Footer from '../footer/Footer'
import './ProjectContent.css'

const ProjectContent = ({ firstSection, secondSection, thirdSection, top }) => {
  return (
    <div className="project-content-container" style={{ top }}>
      <div className="project-content-wrapper">
        <div className="project-content-first-section">
          <p>{firstSection}</p>
        </div>
        <div className="project-content-second-section">
          <p>{secondSection}</p>
        </div>
        <div className="project-content-third-section">{thirdSection}</div>
      </div>
      <Footer containerStyle={{ marginTop: 0 }} />
    </div>
  )
}

export default ProjectContent
