export const BASE_URL = 'https://corparquitetos.com/wp-json/wp/v2'
export const BASE_URL_PLAIN_PERMALINK = 'https://admin.corparquitetos.com/'

export const PROJECT_CATEGORY = 2
export const NEWS_CATEGORY = 3
export const NEWS_EXTERNAL_CATEGORY = 4

export const HOMEPAGE_SLIDESHOW = 63
export const HOMEPAGE_SLIDESHOW_MOBILE = 938
export const FOOTER_POST = 71
export const INFORMATION_POST = 76
export const OPERE_INFORMATION_POST = 149
