import React from 'react'
import './NewsContent.css'

const NewsContent = ({ newsContent }) => (
  <div className="news-content-wrapper">
    {newsContent.content.rendered && (
      <div dangerouslySetInnerHTML={{ __html: newsContent.content.rendered }} />
    )}
  </div>
)

export default NewsContent
