import React from 'react'
import './Loading.css'

const LoadingFull = () => (
  <div className="loading-full-wrapper">
    <div className="loading-full-gif" />
  </div>
)

export default LoadingFull
