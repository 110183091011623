import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import LoadingFull from '../../components/loading/LoadingFull'
import { OPERE_INFORMATION_POST } from '../../constants/wordpress'
import { getPost } from '../../services/posts'
import './Opere.css'
import { debounce } from 'lodash'
import FooterOpere from '../../components/footerOpere/FooterOpere'
import Slideshow from '../../components/slideshow/Slideshow'
import { INSTAGRAM_OPERE_URL } from '../../constants/social'
import NavbarOpere from '../../components/navbarOpere/NavbarOpere'
import ImageLoader from '../../components/image-loader/ImageLoader'

const shuffleArray = (array) => {
  let currentIndex = array.length,
    randomIndex

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
  }

  return array
}

const footerFirstSection = (
  <div className="footer-first-section">
    <p />
    opere@corparquitetos.com
    <br />
    +351 911 020 079
    <br />
    +351 924 298 886
    <p />
    Rua dos Lusíadas 5 2B, Lisboa
    <br />
    1300-365
    <p />
    <a target="_blank" href={INSTAGRAM_OPERE_URL} rel="noreferrer">
      Instagram
    </a>
  </div>
)

const parseOpereInformation = (information) => {
  const postContent = information.content.rendered

  const div = document.createElement('div')
  div.innerHTML = postContent

  const contentImages = div.getElementsByClassName('slideshow')

  if (!contentImages || !contentImages[0]) return false
  const elements = contentImages && contentImages[0].children

  const items = [].slice.call(elements)

  const images = items
    .map((item) => {
      const src = item.children[0].src
      const imageSrcSplit = src.split('?')
      const imageSrc = imageSrcSplit.length > 1 ? imageSrcSplit[0] : src
      return { image: imageSrc, caption: item.children[1].innerHTML }
    })
    .filter(({ image }) => image !== '')

  const contentDescription = div.getElementsByClassName('description')
  const secondSection = contentDescription[0].innerHTML
  const thirdSection = contentDescription[1].innerHTML

  return { images, secondSection, thirdSection }
}

const Opere = () => {
  const [informationPost, setInformationPost] = useState(null)

  const [loading, setLoading] = useState(true)

  const [hasScroll, setHasScroll] = useState(false)
  const [innerHeight, setInnerHeight] = useState(window.innerHeight)

  useEffect(() => {
    setLoading(true)
    getPost(OPERE_INFORMATION_POST, (post) => {
      setInformationPost(post)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setInnerHeight(window.innerHeight)
    }, 100)

    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [informationPost])

  const handlePage = useCallback(
    (node) => {
      if (!node) return
      setTimeout(() => {
        setHasScroll(node.clientHeight < node.scrollHeight)
      }, 200)
    },
    [innerHeight, informationPost]
  )

  const {
    images = [],
    secondSection = '',
    thirdSection = ''
  } = (informationPost && parseOpereInformation(informationPost)) || {}

  const imagesPreload = images?.length > 0 && images.map(({ image }) => image)

  const slideshowImages = images && images.length > 0 ? shuffleArray(images) : []

  return (
    <div className="page">
      <div className="page-scrollable-content opere-page" ref={handlePage}>
        <NavbarOpere opereMode={true} />
        {loading && <LoadingFull />}
        {images?.length > 0 && <ImageLoader imagesToLoad={imagesPreload} />}
        {slideshowImages?.length > 0 && <Slideshow imageArray={slideshowImages} />}
        {/* <div className="opere-information-content-wrapper">
          {description && (
            <div
              className="opere-information-content"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </div> */}
        {hasScroll && (
          <FooterOpere
            firstSection={footerFirstSection}
            secondSectionFooter={<div dangerouslySetInnerHTML={{ __html: secondSection }} />}
            thirdSectionFooter={<div dangerouslySetInnerHTML={{ __html: thirdSection }} />}
            containerStyle={{ marginTop: 0 }}
          />
        )}
      </div>
      {!hasScroll && (
        <FooterOpere
          firstSection={footerFirstSection}
          secondSectionFooter={<div dangerouslySetInnerHTML={{ __html: secondSection }} />}
          thirdSectionFooter={<div dangerouslySetInnerHTML={{ __html: thirdSection }} />}
        />
      )}
    </div>
  )
}

export default Opere
