import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import Footer from '../../components/footer/Footer'
import LoadingFull from '../../components/loading/LoadingFull'
import Navbar from '../../components/navbar/Navbar'
import { INFORMATION_POST } from '../../constants/wordpress'
import { getPost } from '../../services/posts'
import './Information.css'
import { debounce } from 'lodash'

const parseInformation = (information) => {
  const postContent = information.content.rendered

  const div = document.createElement('div')
  div.innerHTML = postContent

  const contentDescription = div.getElementsByClassName('description')
  const description = contentDescription[0].innerHTML

  const contentFounders = div.getElementsByClassName('founders')
  const founders = contentFounders[0].innerHTML

  const contentProcess = div.getElementsByClassName('process')
  const process = contentProcess[0].innerHTML

  const contentMultiplicity = div.getElementsByClassName('multiplicity')
  const multiplicity = contentMultiplicity[0].innerHTML

  const contentPress = div.getElementsByClassName('press')
  const press = contentPress[0].innerHTML

  return { description, founders, process, multiplicity, press }
}

const Information = () => {
  const [informationPost, setInformationPost] = useState(null)

  const [loading, setLoading] = useState(true)

  const [hasScroll, setHasScroll] = useState(false)
  const [innerHeight, setInnerHeight] = useState(window.innerHeight)

  useEffect(() => {
    setLoading(true)
    getPost(INFORMATION_POST, (post) => {
      setInformationPost(post)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setInnerHeight(window.innerHeight)
    }, 100)

    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [informationPost])

  const handlePage = useCallback(
    (node) => {
      if (!node) return
      setTimeout(() => {
        setHasScroll(node.clientHeight < node.scrollHeight)
      }, 200)
    },
    [innerHeight, informationPost]
  )

  const {
    description = '',
    founders = '',
    process = '',
    multiplicity = '',
    press = ''
  } = (informationPost && parseInformation(informationPost)) || {}

  return (
    <div className="page">
      <div className="page-scrollable-content" ref={handlePage}>
        <Navbar />
        {loading && <LoadingFull />}
        <div className="information-content-wrapper">
          <div className="information-content-first-section">
            {description && (
              <div
                className="information-content"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
            {founders && (
              <div
                className="information-content-founders"
                dangerouslySetInnerHTML={{ __html: founders }}
              />
            )}
          </div>
          {process && (
            <div className="information-content" dangerouslySetInnerHTML={{ __html: process }} />
          )}
          {multiplicity && (
            <div
              className="information-content"
              dangerouslySetInnerHTML={{ __html: multiplicity }}
            />
          )}
          {press && (
            <div
              className="information-press-content"
              dangerouslySetInnerHTML={{ __html: press }}
            />
          )}
        </div>
        {hasScroll && <Footer containerStyle={{ marginTop: 0 }} />}
      </div>
      {!hasScroll && <Footer />}
    </div>
  )
}

export default Information
