import React from 'react'

const ImageLoader = ({ imagesToLoad }) => (
  <div style={{ display: 'none' }}>
    {imagesToLoad.map((src) => (
      <img src={src} layout="fill" alt="corp homepage hidden image." key={`${src}-key`} />
    ))}
  </div>
)

export default ImageLoader
