import { BASE_URL_PLAIN_PERMALINK as BASE_URL } from '../constants/wordpress'

export const fetchTags = (callback) =>
  fetch(`${BASE_URL}?rest_route=/wp/v2/tags&page=1&per_page=100`, {
    cache: 'no-store'
  })
    .then((res) => res.json())
    .then(
      (result) => {
        callback(result)
      },
      (error) => {
        console.log(`Error: ${error}`)
      }
    )
    .catch((error) => {
      console.log(`Error: ${error}`)
    })
