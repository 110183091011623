import React from 'react'
import './FilterItem.css'

const FilterItem = ({ label, onClick, isSelected }) => (
  <div className="filter-item-wrapper" onClick={onClick}>
    [<div className={`filter-item-text ${isSelected ? 'selected' : ''}`}>{label}</div>]
  </div>
)

export default FilterItem
