import React, { useState, useEffect } from 'react'
import './Home.css'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import { getPost } from '../../services/posts'
import { HOMEPAGE_SLIDESHOW, HOMEPAGE_SLIDESHOW_MOBILE } from '../../constants/wordpress'
import LoadingFull from '../../components/loading/LoadingFull'
import ShapeImages from '../../components/shapeImages/ShapeImages'
import ImageLoader from '../../components/image-loader/ImageLoader'
import { randomIntFromInterval } from '../../utils/utils'

const parseHomepage = (post, propertiesClass, valuesClass) => {
  const postContent = post.content.rendered

  const div = document.createElement('div')
  div.innerHTML = postContent

  const contentImages = div.getElementsByClassName('wp-block-image')

  const items = [].slice.call(contentImages)

  const images = items
    .map((item) => {
      const src = item.children[0].src
      const imageSrcSplit = src.split('?')
      const imageSrc = imageSrcSplit.length > 1 ? imageSrcSplit[0] : src
      return { image: imageSrc, caption: item.children[1].innerHTML }
    })
    .filter(({ image }) => image !== '')

  const shapePropertiesDocument = div.getElementsByClassName(propertiesClass)

  const shapePropertiesDiv = document.createElement('div')
  shapePropertiesDiv.innerHTML = shapePropertiesDocument[0].innerHTML

  const shapeProperties = [...shapePropertiesDiv.getElementsByTagName('td')].map(
    ({ innerText }) => innerText
  )

  const shapesDocument = div.getElementsByClassName(valuesClass)
  const shapesDiv = document.createElement('div')
  shapesDiv.innerHTML = shapesDocument[0].innerHTML

  const shapes = [...shapesDiv.getElementsByTagName('tr')].map((rowInnerHTML) => {
    const rowArray = [...rowInnerHTML.getElementsByTagName('td')]
    const rowObject = {}

    rowArray.map(({ innerText }, index) => {
      const propertyValueParsed = JSON.parse(innerText.replace(/[“”]+/g, '"'))
      rowObject[shapeProperties[index]] = propertyValueParsed
    })

    return rowObject
  })

  return { images, shapes }
}

const MAX_NUMBER_SHAPES = 5

const getRandomIndexes = (index, maxValue, indexes) => {
  const randomIndex = randomIntFromInterval(0, maxValue)

  if (index === MAX_NUMBER_SHAPES) return indexes

  if (!indexes.includes(randomIndex)) {
    indexes.push(randomIndex)
    return getRandomIndexes(index + 1, maxValue, indexes)
  } else return getRandomIndexes(index, maxValue, indexes)
}

const Home = () => {
  const [images, setImages] = useState([])
  const [shapes, setShapes] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    const isMobile = innerWidth <= 650

    if (isMobile) {
      getPost(HOMEPAGE_SLIDESHOW_MOBILE, (result) => {
        const { images: allImages, shapes } = parseHomepage(result, 'properties', 'postIds') || []

        const indexes = getRandomIndexes(0, allImages.length - 1, [])

        const images = indexes.map((index) => allImages[index])

        setImages(images)
        setShapes(shapes)
        setLoading(false)
      })
    } else {
      getPost(HOMEPAGE_SLIDESHOW, (result) => {
        const { images: allImages, shapes: allShapes } =
          parseHomepage(result, 'shapeProperties', 'shapes') || []

        const indexes = getRandomIndexes(0, allImages.length - 1, [])

        const images = indexes.map((index) => allImages[index])
        const shapes = indexes.map((index) => allShapes[index])

        setImages(images)
        setShapes(shapes)
        setLoading(false)
      })
    }
  }, [])

  const imagesPreload = images?.length > 0 && images.map(({ image }) => image)

  return (
    <div className="page">
      <div className="page-content">
        <Navbar />
        {images?.length > 0 && <ImageLoader imagesToLoad={imagesPreload} />}
        {loading ? <LoadingFull /> : <ShapeImages shapes={shapes} images={images} />}
      </div>
      <Footer />
    </div>
  )
}

export default Home
