import React, { useState, useEffect } from 'react'
import './ShapeImages.css'
import { Stage, Layer, Circle, Line, Rect } from 'react-konva'
import * as blankImage from '../../assets/images/blank.png'
import { useNavigate } from 'react-router-dom'
import { debounce } from 'lodash'
import ShapeImagesMobile from './ShapeImagesMobile'

const getCanvasWidth = (innerWidth) => {
  const diff = 80
  const width = innerWidth - diff
  return { width, diff }
}

const getCanvasHeight = (innerWidth, innerHeight) => {
  const max = innerWidth
  const height = max * (SCALE_HEIGHT / SCALE_WIDTH)
  const diff = innerHeight - NAVBAR_HEIGHT - height
  return { height, diff }
}

const SCALE_WIDTH = 1440
const SCALE_HEIGHT = 579

const NAVBAR_HEIGHT = 50

const renderShape = (shape, selectedShapeIndex, setSelectedShapeIndex, index, navigate) => {
  const { type, x, y, color, postId, params } = shape

  const eventProps = {
    onMouseOver: () => {
      document.body.style.cursor = 'pointer'
      setSelectedShapeIndex(index)
    },
    onMouseLeave: () => {
      document.body.style.cursor = 'default'
      setSelectedShapeIndex(null)
    },
    onClick: () => {
      document.body.style.cursor = 'default'
      navigate(`projects/${postId}`)
    }
  }

  switch (type) {
    case 'Line':
      return (
        <>
          <Line
            x={x}
            y={y}
            stroke={'transparent'}
            strokeWidth={12}
            fillEnabled={false}
            visible={!selectedShapeIndex || selectedShapeIndex === index}
            {...eventProps}
            {...params}
          />
          <Line
            x={x}
            y={y}
            stroke={color}
            strokeWidth={2}
            fillEnabled={false}
            visible={!selectedShapeIndex || selectedShapeIndex === index}
            {...eventProps}
            {...params}
          />
        </>
      )
    case 'Rect':
      return (
        <>
          <Rect
            x={x}
            y={y}
            stroke={'transparent'}
            strokeWidth={12}
            fillEnabled={false}
            visible={!selectedShapeIndex || selectedShapeIndex === index}
            {...eventProps}
            {...params}
          />
          <Rect
            x={x}
            y={y}
            stroke={color}
            strokeWidth={2}
            fillEnabled={false}
            visible={!selectedShapeIndex || selectedShapeIndex === index}
            {...eventProps}
            {...params}
          />
        </>
      )
    case 'Circle':
      return (
        <>
          <Circle
            x={x}
            y={y}
            stroke={'transparent'}
            strokeWidth={12}
            fillEnabled={false}
            visible={!selectedShapeIndex || selectedShapeIndex === index}
            {...eventProps}
            {...params}
          />
          <Circle
            x={x}
            y={y}
            stroke={color}
            strokeWidth={2}
            fillEnabled={false}
            visible={!selectedShapeIndex || selectedShapeIndex === index}
            {...eventProps}
            {...params}
          />
        </>
      )
  }
}

const ShapeImages = ({ shapes, images }) => {
  const [selectedShapeIndex, setSelectedShapeIndex] = useState(null)
  const [dimensions, setDimensions] = useState({
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth
  })

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setDimensions({
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth
      })
    }, 100)

    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  const navigate = useNavigate()

  const { innerWidth, innerHeight } = dimensions

  const { width, diff: diffWidth } = getCanvasWidth(innerWidth)
  const { height, diff: diffHeight } = getCanvasHeight(width, innerHeight)

  const scaleX = width / SCALE_WIDTH
  const scaleY = height / SCALE_HEIGHT

  const isMobile = innerWidth <= 650

  return isMobile ? (
    <ShapeImagesMobile shapes={shapes} images={images} />
  ) : (
    <>
      <div
        style={{
          zIndex: 1,
          position: 'absolute',
          left: diffWidth / 2,
          top: (NAVBAR_HEIGHT + diffHeight) / 2
        }}>
        <Stage width={width} height={height} scale={{ x: scaleX, y: scaleY }}>
          <Layer>
            {shapes.map((shape, index) =>
              renderShape(shape, selectedShapeIndex, setSelectedShapeIndex, index + 1, navigate)
            )}
          </Layer>
        </Stage>
      </div>
      <div
        className="image-wrapper"
        style={{ width, height, left: diffWidth / 2, top: (NAVBAR_HEIGHT + diffHeight) / 2 }}>
        <div
          className="image-background"
          style={
            images && images.length > 0 && selectedShapeIndex
              ? {
                  backgroundImage: `url(${images[selectedShapeIndex - 1].image})`,
                  opacity: 1
                }
              : {
                  backgroundImage: `url(${blankImage})`,
                  opacity: 0
                }
          }>
          {images && images.length > 0 && selectedShapeIndex && (
            <div className="image-caption">{images[selectedShapeIndex - 1].caption}</div>
          )}
        </div>
      </div>
    </>
  )
}

export default ShapeImages
