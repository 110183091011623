import React, { useState } from 'react'
import './ShapeImages.css'
import Fade from 'react-reveal/Fade'
import { Fade as SlideshowFade } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'
import { useNavigate } from 'react-router-dom'

const properties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  autoplay: true,
  prevArrow: <div className="slideshow-arrow">←</div>,
  nextArrow: <div className="slideshow-arrow">→</div>
}

const ShapeImagesMobile = ({ shapes, images }) => {
  const [selectedShapeIndex, setSelectedShapeIndex] = useState(0)
  const navigate = useNavigate()

  return (
    <div
      style={{
        height: 'calc(100vh - 100px)',
        display: 'flex'
      }}>
      <div style={{ margin: 'auto' }}>
        <Fade>
          <div className="slideshow-mobile-wrapper">
            <SlideshowFade
              {...properties}
              onChange={(_, newIndex) => setSelectedShapeIndex(newIndex)}>
              {images.map(({ image }, index) => (
                <div key={`shape-image-${index}`} className="image-wrapper-mobile">
                  <div
                    className="image-background"
                    style={{ backgroundImage: `url(${image})` }}
                    onClick={() => navigate(`projects/${shapes[selectedShapeIndex].postId}`)}
                  />
                </div>
              ))}
            </SlideshowFade>
          </div>
        </Fade>
        <div
          className="image-caption"
          style={{ margin: 'auto', textAlign: 'center', marginTop: 40 }}
          onClick={() => navigate(`projects/${shapes[selectedShapeIndex].postId}`)}>
          {images[selectedShapeIndex].caption}
        </div>
      </div>
    </div>
  )
}

export default ShapeImagesMobile
