import React from 'react'
import { useContext } from 'react'
import { FACEBOOK_URL, INSTAGRAM_URL, LINKEDIN_URL } from '../../constants/social'
import { AppContext } from '../../context/App'
import './Footer.css'

const parseFooter = (footer) => {
  const footerContent = footer.content.rendered

  const div = document.createElement('div')
  div.innerHTML = footerContent

  const textArray = div.getElementsByClassName('text')

  const bottom = div.getElementsByClassName('bottom')

  return {
    secondSection: textArray[0].innerHTML || '',
    thirdSection: textArray[1].innerHTML || '',
    bottom: bottom[0].innerHTML || ''
  }
}

const footerFirstSection = (
  <div className="footer-first-section">
    <p />
    geral@corparquitetos.com
    <br />
    +351 911 020 079
    <br />
    +351 924 298 886
    <p />
    Rua dos Lusíadas 5 2B, Lisboa
    <br />
    1300-365
    <p />
    <a target="_blank" href={INSTAGRAM_URL} rel="noreferrer">
      Instagram
    </a>
    <br />
    <a target="_blank" href={FACEBOOK_URL} rel="noreferrer">
      Facebook
    </a>
    <br />
    <a target="_blank" href={LINKEDIN_URL} rel="noreferrer">
      LinkedIn
    </a>
  </div>
)

const Footer = ({
  firstSection = footerFirstSection,
  secondSectionFooter,
  thirdSectionFooter,
  height /* = 200 */,
  containerStyle
}) => {
  const { footerInfo } = useContext(AppContext)

  const {
    secondSection = '',
    thirdSection = '',
    bottom = ''
  } = (footerInfo && parseFooter(footerInfo)) || {}

  return (
    <div className="footer-container" style={containerStyle}>
      <div className="footer-wrapper" style={{ height }}>
        <div className="footer-first-section">{firstSection}</div>
        <div className="footer-second-section">
          <p />
          {secondSectionFooter || <div dangerouslySetInnerHTML={{ __html: secondSection }} />}
        </div>
        <div className="footer-third-section">
          <p />
          {thirdSectionFooter || <div dangerouslySetInnerHTML={{ __html: thirdSection }} />}
        </div>
      </div>
      {/* <div className="footer-bottom">
        © 2021 CO.RP
        <br />
        [Design by Abel Quental] [Development by Francisco Ribeiro]
      </div> */}
      <div className="footer-bottom" dangerouslySetInnerHTML={{ __html: bottom }} />
    </div>
  )
}

export default Footer
