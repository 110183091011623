import React from 'react'
import './Loading.css'

const Loading = ({ label }) => (
  <div className="loading-small-wrapper">
    [<div className="loading-small-label">{label}</div>]
  </div>
)

export default Loading
