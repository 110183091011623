import React from 'react'
import { Link } from 'react-router-dom'
import './NavbarOpere.css'

const NavbarOpere = () => (
  <div className="navbar-opere-wrapper">
    <div className="navbar-opere-corp-wrapper">
      <Link to="/" className="navbar-opere-corp">
        by CO·RP
      </Link>
    </div>
    <div className="navbar-opere-logo-wrapper">
      <div className="navbar-opere-logo">OPERE</div>
    </div>
  </div>
)

export default NavbarOpere
