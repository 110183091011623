import React from 'react'
import './Slideshow.css'

const SlideshowItem = ({ imageUrl, caption, index, numberImages }) => {
  return (
    <>
      <div
        className="slideshow-item-wrapper"
        style={{
          backgroundImage: `url(${imageUrl})`
        }}></div>
      <div className="slideshow-item-caption">
        <span className="slideshow-item-caption-number">{`[${index + 1}/${numberImages}]`}</span>
        {` ${caption}`}
      </div>
    </>
  )
}

export default SlideshowItem
