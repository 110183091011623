import { BASE_URL_PLAIN_PERMALINK as BASE_URL } from '../constants/wordpress'

export const fetchPostsDatePreview = (
  categoryNum,
  page,
  perPage,
  otherQueryParams = '',
  callback
) =>
  fetch(
    `${BASE_URL}?rest_route=/wp/v2/posts&categories=${categoryNum}&page=${page}&per_page=${perPage}${otherQueryParams}&_embed`,
    {
      cache: 'no-store'
    }
  )
    .then((res) => ({
      result: res.json(),
      total: res.headers.get('X-WP-Total')
    }))
    .then(
      ({ result, total }) => {
        result.then((posts) => callback(posts, Number(total)))
      },
      (error) => {
        console.log(`Error: ${error}`)
      }
    )
    .catch((error) => {
      console.log(`Error: ${error}`)
    })

export const fetchPostsPreview = (categoryNum, page, perPage, callback) =>
  fetch(
    `${BASE_URL}?rest_route=/wp/v2/posts&categories=${categoryNum}&page=${page}&per_page=${perPage}&_embed`,
    {
      cache: 'no-store'
    }
  )
    .then((res) => ({
      result: res.json(),
      total: res.headers.get('X-WP-Total')
    }))
    .then(
      ({ result, total }) => {
        result.then((posts) => callback(posts, Number(total)))
      },
      (error) => {
        console.log(`Error: ${error}`)
      }
    )
    .catch((error) => {
      console.log(`Error: ${error}`)
    })

export const fetchPostSearchPreview = (categoryNum, search, page, perPage, callback) =>
  fetch(
    `${BASE_URL}?rest_route=/wp/v2/posts&categories=${categoryNum}&search=${search}&page=${page}&per_page=${perPage}&_embed`,
    {
      cache: 'no-store'
    }
  )
    .then((res) => ({
      result: res.json(),
      total: res.headers.get('X-WP-Total')
    }))
    .then(
      ({ result, total }) => {
        result.then((posts) => callback(posts, Number(total)))
      },
      (error) => {
        console.log(`Error: ${error}`)
      }
    )
    .catch((error) => {
      console.log(`Error: ${error}`)
    })

export const getPost = (id, callback) =>
  fetch(`${BASE_URL}?rest_route=/wp/v2/posts/${id}&_embed`, {
    cache: 'no-store'
  })
    .then((res) => res.json())
    .then(
      (result) => {
        callback(result)
      },
      (error) => {
        console.log(`Error: ${error}`)
      }
    )
    .catch((error) => {
      console.log(`Error: ${error}`)
    })
