import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import './Projects.css'
import { fetchPostsPreview } from '../../services/posts'
import { fetchTags } from '../../services/tags'
import { PROJECT_CATEGORY } from '../../constants/wordpress'
import Navbar from '../../components/navbar/Navbar'
import ListItem from '../../components/listItem/ListItem'
import Footer from '../../components/footer/Footer'
import { randomIntFromInterval } from '../../utils/utils'
import LoadingFull from '../../components/loading/LoadingFull'
import { useNavigate } from 'react-router'
import { debounce } from 'lodash'
import ImageLoader from '../../components/image-loader/ImageLoader'

const { innerWidth: pageWidth, innerHeight: pageHeight } = window

const IMAGE_WIDTH = 350
const IMAGE_HEIGHT = 250

const getRandomWidth = () => randomIntFromInterval(0, pageWidth - IMAGE_WIDTH)
const getRandomHeight = () => randomIntFromInterval(0, pageHeight - IMAGE_HEIGHT)

const getTagNames = (tags = [], tagsList = []) =>
  tags.map((tag) => {
    const { name = '' } =
      tagsList.length > 0 &&
      tagsList.find(({ id }) => {
        return id === tag
      })
    return name || ''
  })

const Projects = () => {
  const [projectList, setProjectList] = useState([])
  const [tagsList, setTagsList] = useState([])
  const [imageInBackground, setImageInBackground] = useState('')

  const [loading, setLoading] = useState(true)
  const [innerHeight, setInnerHeight] = useState(window.innerHeight)
  const [hasScroll, setHasScroll] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    fetchPostsPreview(PROJECT_CATEGORY, 1, 60, setProjectList)
    fetchTags(setTagsList)
  }, [])

  useEffect(() => {
    if (projectList.length > 0 && tagsList.length > 0) setLoading(false)
  }, [projectList.length, tagsList.length])

  const ref = useRef(null)

  useEffect(() => {
    if (!ref || !ref.current) return
    setHasScroll(ref.current.clientHeight + 144 > innerHeight)
  }, [ref.current, innerHeight])

  const debouncedHandleResize = debounce(() => {
    setInnerHeight(window.innerHeight)
  }, 100)

  useLayoutEffect(() => {
    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  const imagesPreload =
    projectList?.length > 0 &&
    projectList.map(({ jetpack_featured_media_url }) => jetpack_featured_media_url)

  return (
    <div className="page">
      <div className="project-hidden-section">
        <div
          style={{
            width: IMAGE_WIDTH,
            height: IMAGE_HEIGHT,
            marginLeft: getRandomWidth(),
            marginTop: getRandomHeight(),
            backgroundImage: `url(${imageInBackground})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain'
          }}
        />
      </div>
      <div className="page-scrollable-content">
        <Navbar />
        {loading && <LoadingFull />}
        {projectList?.length > 0 && <ImageLoader imagesToLoad={imagesPreload} />}
        {projectList?.length > 0 && (
          <div className="project-list-wrapper" ref={ref}>
            {projectList.map((project) => (
              <ListItem
                key={project.id}
                item={project}
                styleItem={{ gridTemplateColumns: '0.35fr 0.65fr' }}
                renderFields={({ tags }) => {
                  const tagNames = getTagNames(tags, tagsList)
                  return (
                    <div className="project-list-item">
                      <div></div>
                      {tagNames.sort().map((tag, index) => {
                        const parsedTag = tag[0] === '|' ? tag.replace('|', '') : tag
                        return <div key={`tag-${index}`}>{parsedTag}</div>
                      })}
                    </div>
                  )
                }}
                onMouseEnter={() => setImageInBackground(project.jetpack_featured_media_url)}
                onMouseLeave={() => setImageInBackground('')}
                onClick={() => navigate(`/projects/${project.id}`)}
              />
            ))}
          </div>
        )}
        {hasScroll && <Footer containerStyle={{ marginTop: 0 }} />}
      </div>
      {!hasScroll && <Footer />}
    </div>
  )
}

export default Projects
